import { router } from "@/routes";
import { userService } from "../services";

var user = JSON.parse(localStorage.getItem("user"));
const theme = localStorage.getItem("theme")
  ? localStorage.getItem("theme")
  : "light";
const initialState = user
  ? { status: { loggedIn: true }, user, theme: theme }
  : { status: {}, user: null, theme: theme };

export const authentication = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ dispatch, commit }, { username, password }) {
      commit("loginRequest", { username });

      userService.login(username, password).then(
        (user) => {
          commit("loginSuccess", user);
          router.push("/");
        },
        (error) => {
          commit("loginFailure", error);
          dispatch("alert/error", error, { root: true });
        }
      );
    },
    proUpdate({ commit }, programas) {
      if (programas) {
        console.log("programas");
        console.log(programas);
        commit("programasUpdated", programas);
      }
    },
    logout({ commit }) {
      userService.logout();
      commit("logout");
    },
    changetheme({ commit }, themeValue) {
      commit("theme", themeValue);
    },
  },
  mutations: {
    loginRequest(state, user) {
      state.status = { loggingIn: true };
      state.user = user;
    },
    loginSuccess(state, user) {
      state.status = { loggedIn: true };
      state.user = user;
    },
    programasUpdated(state, programas) {
      state.user.programas = programas;
    },
    loginFailure(state) {
      state.status = {};
      state.user = null;
    },
    logout(state) {
      state.status = {};
      state.user = null;
    },
    theme(state, value) {
      state.theme = value;
    },
  },
};

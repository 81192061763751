import Vue from "vue";
import VueRouter from "vue-router";

import AppPage from "../apps/AppPage.vue";
import PageLogin from "../pages/PageLogin.vue";
import PageInicio from "../pages/PageInicio.vue";
import PageAdmin from "../pages/PageAdmin.vue";
import PageDownloadPDFTest from "../pages/PageDownloadPDFTest";
import PageUpdateRowData from "@/pages/PageUpdateRowData"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: AppPage,
    children: [
      {
        path: "/",
        component: PageInicio,
      },
    ],
  },
  {
    path: "/pdf",
    component: PageDownloadPDFTest,
  },
  {
    path: "/updaterd",
    component: PageUpdateRowData,
  },
  {
    path: "/admin",
    component: PageAdmin,
    meta: { onlyAuthUser: true },
  },
  {
    path: "/login",
    component: PageLogin,
  },
  {
    path: "/*",
  },
];

export const router = new VueRouter({
  routes, // short for `routes: routes`
  mode: "history",
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  var now = new Date();
  var now_utc = new Date(now.toUTCString().slice(0, -4));

  var isTokeExpire = false;
  if (loggedIn) {
    const token = JSON.parse(loggedIn);
    const noneUtcTokenDate = new Date(token.tokeExpireIn);
    var utcDate = new Date(noneUtcTokenDate.toUTCString().slice(0, -4));
    isTokeExpire = now_utc >= utcDate;
  }

  if (authRequired && !loggedIn) {
    return next("/login");
  }

  if (authRequired && isTokeExpire) {
    return next("/login");
  }

  const user = JSON.parse(loggedIn);
  if (to.meta.onlyAuthUser) {
    if (user.isMainAdmin) {
      next();
    } else {
      return next("/");
    }
  }

  next();
});

<template>
  <canvas :id="idchart" height="100"></canvas>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "PageLineStack",
  props: {
    idchart: {
      type: String,
      default: "bar-chart",
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.OnSetChartBar();
  },
  methods: {
    OnSetChartBar() {
      const ctx = document.getElementById(this.idchart).getContext("2d");
      new Chart(ctx, {
        type: "line",
        data: {
          labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
          datasets: [
            {
              label: "# Top 7",
              data: [12, 19, 3, 5, 2, 3],
              backgroundColor: [
                "black",
                "black",
                "black",
                "black",
                "black",
                "black",
              ],
              borderColor: [
                "black",
                "black",
                "black",
                "black",
                "black",
                "black",
              ],
              borderWidth: 1,
            },
            {
              label: "# Top 7",
              data: [12, 19, 5, 5, 8, 3],
              backgroundColor: [
                "#6c757d",
                "#6c757d",
                "#6c757d",
                "#6c757d",
                "#6c757d",
                "#6c757d",
              ],
              borderColor: [
                "#6c757d",
                "#6c757d",
                "#6c757d",
                "#6c757d",
                "#6c757d",
                "#6c757d",
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          animations: {
            tension: {
              duration: 1000,
              easing: "linear",
              from: 1,
              to: 0,
              loop: true,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
  },
};
</script>

<template>
  <!-- <canvas :id="idchart"></canvas> -->
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="idchart"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
  />
</template>

<script>
/* eslint-disable */
import Chart from "chart.js/auto";
import { Bar } from "vue-chartjs";

export default {
  name: "PageBar",
  components: { Bar },
  props: {
    idchart: {
      type: String,
      default: "bar-chart",
    },
    dataset: {
      type: Array,
      default: () => [],
    },
    datalabels: {
      type: Array,
      default: () => [],
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      type: String,
      default: "",
    },
    styles: {
      type: Object,
      default: () => {
        return { width: "100%" };
      },
    },
    plugins: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
      },
    };
  },
  mounted() {
    // this.OnSetChartBar();
  },
  computed: {
    chartData() {
      const resp = {
        labels: this.datalabels,
        datasets: this.dataset,
      };
      return resp;
    },
  },
  methods: {
    OnSetChartBar() {
      var color = [];
      this.dataset.forEach(() => {
        color.push("black");
      });
      const ctx = document.getElementById(this.idchart).getContext("2d");
      new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.datalabels,
          datasets: [
            {
              label: "TVMAX",
              data: this.dataset,
              backgroundColor: color,
              borderColor: color,
              borderWidth: 1,
            },
          ],
        },
        options: {
          animations: {
            tension: {
              duration: 1000,
              easing: "linear",
              from: 1,
              to: 0,
              loop: true,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
  },
};
</script>

export const fetchparam = {
  namespaced: true,
  state: {
    type: null,
    data: null,
  },
  actions: {
    setParams({ commit }, data) {
      commit("setParams", data);
    },
    clear({ commit }) {
      commit("clear");
    },
  },
  mutations: {
    setParams(state, data) {
      state.data = data;
    },
    clear(state) {
      state.data = null;
    },
  },
};

<template>
  <div>
    <div
      class="d-flex flex-column justify-content-center align-items-center my-5"
    >
      <p class="p4">Download PDF test</p>
      <div>
        <button @click="generateReport" class="btn btn-sm btn-dark">
          Donwload
        </button>
      </div>
    </div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="filename"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <!-- PDF Content Here -->
      <PageInicioVue
        @domRendered="domRendered()"
        slot="pdf-content"
      ></PageInicioVue>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import PageInicioVue from "@/pages/PageInicio.vue";

export default {
  data() {
    return {
      contentRendered: false,
    };
  },
  components: {
    VueHtml2pdf,
    PageInicioVue,
  },
  methods: {
    /*
            Generate Report using refs and calling the
            refs function generatePdf()
        */
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },

    domRendered() {
      console.log("Dom Has Rendered");
      this.contentRendered = true;
    },
  },
};
</script>

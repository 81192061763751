<template>
  <MainLayout></MainLayout>
</template>

<script>
import MainLayout from "@/layout/MainLayout.vue";
export default {
  name: "AppPage",
  components: { MainLayout },
};
</script>

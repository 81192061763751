var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-12 col-lg"},[_c('div',{staticClass:"row justify-content-center align-items-strech mt-4 mt-xl-0"},[_c('div',{staticClass:"col col-sm-8 col-md-9 col-lg-6"},[_c('div',{class:`${
              _vm.theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
            } p-3`},[_vm._m(0),_c('Bar',{attrs:{"idchart":"11","datalabels":_vm.BarRatingPrograma.labels,"dataset":_vm.BarRatingPrograma.dataset}})],1)]),_c('div',{staticClass:"col col-sm-8 col-md-9 col-lg-6"},[_c('div',{class:`${
              _vm.theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
            } p-3`},[_vm._m(1),_c('BarY',{attrs:{"idchart":"12","datalabels":_vm.BarY.labels,"dataset":_vm.BarY.dataset,"title":_vm.BarY.title}})],1)]),_c('div',{staticClass:"col col-sm-8 col-md-9 col-lg-6 mt-2"},[_c('div',{class:`${
              _vm.theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
            } p-3`},[_vm._m(2),_c('Doughnut',{attrs:{"idchart":"13","datalabels":_vm.DoughnutRG.labels,"dataset":_vm.DoughnutRG.dataset}})],1)]),_c('div',{staticClass:"col col-sm-8 col-md-9 col-lg-6 mt-2"},[_c('div',{class:`${
              _vm.theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
            } p-3`},[_vm._m(3),_c('Doughnut',{attrs:{"idchart":"14","datalabels":_vm.DoughnutNSE.labels,"dataset":_vm.DoughnutNSE.dataset}})],1)]),_c('div',{staticClass:"col col-sm-8 col-md-9 col-lg-6 mt-2"},[_c('div',{class:`${
              _vm.theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
            } p-3`},[_vm._m(4),_c('Bar',{attrs:{"idchart":"15","datalabels":_vm.BarAP.labels,"dataset":_vm.BarAP.dataset}})],1)]),_c('div',{staticClass:"col col-sm-8 col-md-9 col-lg-6 mt-2"},[_c('div',{class:`${
              _vm.theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
            } p-3`},[_vm._m(5),_c('Bar',{attrs:{"idchart":"16","datalabels":_vm.BarAPG.labels,"dataset":_vm.BarAPG.dataset}})],1)]),_c('div',{staticClass:"col col-sm-8 col-md-9 col-lg-6 mt-2"},[_c('div',{class:`${
              _vm.theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
            } p-3`},[_vm._m(6),_c('Bar',{attrs:{"idchart":"17","datalabels":_vm.BarFidelidad.labels,"dataset":_vm.BarFidelidad.dataset}})],1)]),_c('div',{staticClass:"col col-sm-8 col-md-9 col-lg-6 mt-2"},[_c('div',{class:`${
              _vm.theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
            } p-3`},[_vm._m(7),_c('Bar',{attrs:{"idchart":"18","datalabels":_vm.BarFPG.labels,"dataset":_vm.BarFPG.dataset}})],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mb-2 align-items-center"},[_c('i',{staticClass:"bi bi-bar-chart me-2"}),_c('p',{staticClass:"p6 text-muted"},[_vm._v("Rating")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mb-2 align-items-center"},[_c('i',{staticClass:"bi bi-bar-chart me-2"}),_c('p',{staticClass:"p6 text-muted"},[_vm._v("Rating por edad")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mb-2 align-items-center"},[_c('i',{staticClass:"bi bi-pie-chart me-2"}),_c('p',{staticClass:"p6 text-muted"},[_vm._v("Rating por genero")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mb-2 align-items-center"},[_c('i',{staticClass:"bi bi-pie-chart me-2"}),_c('p',{staticClass:"p6 text-muted"},[_vm._v("Rating por NSE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mb-2 align-items-center"},[_c('i',{staticClass:"bi bi-bar-chart me-2"}),_c('p',{staticClass:"p6 text-muted"},[_vm._v("Alcance personas 18+")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mb-2 align-items-center"},[_c('i',{staticClass:"bi bi-bar-chart me-2"}),_c('p',{staticClass:"p6 text-muted"},[_vm._v("Alcance personas por genero")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mb-2 align-items-center"},[_c('i',{staticClass:"bi bi-bar-chart me-2"}),_c('p',{staticClass:"p6 text-muted"},[_vm._v("Fidelidad personas 18+")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mb-2 align-items-center"},[_c('i',{staticClass:"bi bi-bar-chart me-2"}),_c('p',{staticClass:"p6 text-muted"},[_vm._v("Fidelidad por genero")])])
}]

export { render, staticRenderFns }
<template>
	<div class="container-fluid">
		<div class="row vh-100 align-items-center justify-content-center">
			<div class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
				<div :class="`p-4 shadow2  ${theme === 'light' ? 'bg-white' : 'bg-dark'}`">
					<div class="d-flex justify-content-between align-items-center">
						<div class="d-flex align-items-center">
							<img @click="OnRedirect" src="../assets/images/tvn.png" class="p-1" style="height: 45px; border-radius: 50%; cursor: pointer" />
							<div class="ms-2">
								<p class="p5">Actualizar row data</p>
								<p class="p4 text-muted">Concesonarios</p>
							</div>
						</div>
						<div>
							<button :class="`btn btn-sn py-1 px-3 rounded-0 ${theme === 'light' ? 'bg-white' : 'btn-dark'}`" @click="onPickFile">
								<div class="d-flex align-items-center">
									<i class="bi bi-upload" style="font-size: 14px"></i>
									<p class="p5 ms-3">Cargar archivo</p>
									<p class="p5 text-muted ms-3">.xlsx</p>
								</div>
							</button>
							<input style="display: none" type="file" class="form-control" id="inputGroupFile02" ref="fileInput" accept=".xlsx" @change="onFilePicked" @click="status = null" />
						</div>
					</div>
					<hr />
					<div class="d-flex align-items-center justify-content-center" style="height: 120px">
						<!-- {{ status }} -->
						<div v-if="!isbusy">
							<div v-if="status == true" class="p-3 d-flex align-items-center">
								<div class="me-2">
									<i class="bi bi-check2" style="font-size: 22px"></i>
								</div>
								<div>
									<p class="p6 text-muted">Archivo cargado</p>
								</div>
							</div>
							<div v-if="status == 'error'" class="p-3 d-flex align-items-center">
								<div class="me-2">
									<i class="bi bi-exclamation-triangle-fill text-muted" style="font-size: 20px"></i>
								</div>
								<div>
									<p class="p6 text-muted">Error al cargar el archivo</p>
								</div>
								<div class="ms-3">
									<i class="bi bi-exclamation-triangle-fill text-muted" style="font-size: 20px"></i>
								</div>
							</div>
						</div>

						<div v-if="isbusy" class="d-flex align-items-center">
							<div class="spinner-border spinner-border-sm text-secondary me-2" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
							<p class="p6 text-muted">Cargando...</p>
						</div>

						<!-- <div>
              <div v-if="DocumentName" class="mt-4">
                <ul>
                  <li>
                    <div class="d-flex">
                      <div class="d-flex align-items-center">
                        <i class="bi bi-file-earmark-arrow-up"></i>
                        <p class="p4 ms-1 fw-bolder">
                          {{ DocumentName }}
                        </p>
                      </div>
                      <button
                        class="btn btn-sm btn-outline py-1 p-2 ms-3"
                        style="background-color: whitesmoke"
                      >
                        <p class="p5">Subir</p>
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </div> -->
					</div>
					<hr />
					<div>
						<button @click="OnRedirect" :class="`btn btn-sm  ${theme === 'light' ? 'btn-light' : 'btn-dark'}`">
							<i class="bi bi-arrow-left" style="font-size: 14px"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	/* eslint-disable */
	const XLSX = require("xlsx");
	import { userService } from "../services/index";

	export default {
		name: "PageUpdateRowData",
		data() {
			return {
				DocumentUrl: null,
				DocumentName: null,
				data: null,
				status: "",
				isbusy: false,
			};
		},
		methods: {
			onPickFile() {
				this.$refs.fileInput.click();
			},
			async onFilePicked(event) {
				try {
					this.isbusy = true;
					const file = event.target.files[0];

					var formFIle = new FormData();

					var fileReader = new FileReader();
					fileReader.Status = null;
					fileReader.onload = async function (e) {
						function formatNumber(number) {
							const formattedNumber = `${number}`.replace(/,/g, "");
							const parsedNumber = parseFloat(formattedNumber);
							const nonThousandFormat = parsedNumber.toLocaleString("en-US", { useGrouping: false });
							return nonThousandFormat;
						}

						var data = e.target.result;
						var workbook = XLSX.read(data, {
							cellDates: true,
							dateNF: "mm/dd/yyyy",
						});
						var sheet_name_list = workbook.SheetNames;

						const worksheet = workbook.Sheets[sheet_name_list[1]];

						const range = XLSX.utils.decode_range(worksheet["!ref"]);
						const numRows = range.e.r; // Add 1 to include the header row
						const numCells = range.e.c + 1;

						// Convert the range of cells from thousand format to non-thousand format
						for (let row = 2; row <= numRows; row++) {
							for (let col = 4; col <= numCells; col++) {
								const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
								const cell = worksheet[cellAddress];
								if (cell && cell.t === "n") {
									const formattedNumber = formatNumber(cell.v);
									cell.v = parseFloat(formattedNumber);
									cell.w = formattedNumber; // Update the displayed value in the cell
								}
							}
						}

						// Save the modified workbook
						XLSX.write(workbook, { type: "buffer", bookType: "xlsx" });



						var xlData = XLSX.utils.sheet_to_csv(workbook.Sheets[sheet_name_list[1]], { RS: "\\n" });
						var customfile = new File([xlData], "file", {
							type: "text/csv",
						});
						var formtempFIle = new FormData();
						formtempFIle.append("file", customfile);
						await userService.getDataForm("/upload", formtempFIle);
					};

					fileReader.readAsArrayBuffer(file);
					fileReader.onloadend = (event) => {
						this.isbusy = false;
						this.status = event.returnValue;
					};
				} catch (error) {
					this.DocumentUrl = null;
					this.DocumentName = null;
					this.status = error;
				}
			},
			handleDrop() {
				e.stopPropagation();
				e.preventDefault();
				var f = e.dataTransfer.files[0];
				/* f is a File */
				var reader = new FileReader();
				reader.onload = function (e) {
					var data = e.target.result;
					var workbook = XLSX.read(data);
				};
				reader.readAsArrayBuffer(f);
			},
			OnRedirect() {
				this.$router.push("/");
			},
			formatNumber(number) {
				const formattedNumber = number.replace(/,/g, "");
				const parsedNumber = parseFloat(formattedNumber);
				const nonThousandFormat = parsedNumber.toLocaleString("en-US", { useGrouping: false });
				return nonThousandFormat;
			},
		},
		computed: {
			theme() {
				return this.$store.state.authentication.theme;
			},
		},
	};
</script>

<template>
  <header>
    <div :class="`${classnames}`">
      <div
        :class="`d-flex flex-wrap align-items-center justify-content-center  justify-content-lg-start text-bg-white p-2 shadow2 ${
          theme === 'light' ? 'bg-white' : 'bg-dark'
        }`"
        style=""
      >
        <ul
          class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0"
        >
          <li>
            <div class="d-flex align-items-center">
              <img
                src="../assets/images/tvn.png"
                class="shadow2 p-1"
                style="height: 50px; border-radius: 50%"
              />
              <div class="ms-2">
                <a
                  href="#"
                  class="nav-link p-0"
                  style="color: var(--font-color)"
                  >Concesonarios /
                  {{ this.User.programas.split(",").length }} programas</a
                >
                <div class="d-flex align-items-center">
                  <p class="p2 text-muted">TVN</p>
                  <input
                    class="form-check-input ms-4 m-0"
                    type="checkbox"
                    v-model="themeInput"
                    @change="switchTheme"
                  />
                  <p class="p3 ms-2">Mode theme</p>
                </div>
              </div>
            </div>
          </li>
          <!-- <li><a href="#" class="nav-link px-2 text-black">Features</a></li> -->
        </ul>

        <PdfLayoutChartsVue></PdfLayoutChartsVue>
        <div class="me-3">
          <p class="p-0 m-0" style="font-size: 10px">Loged in as</p>
          <p class="p-0 m-0">{{ User.firstName }} {{ User.lastName }}</p>
        </div>
        <div class="dropdown text-end">
          <a
            href="#"
            class="d-block link-dark text-decoration-none dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              fill="currentColor"
              class="bi bi-person-circle"
              viewBox="0 0 16 16"
              style="color: var(--font-color)"
            >
              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
              <path
                fill-rule="evenodd"
                d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
              />
            </svg>
          </a>
          <ul class="dropdown-menu text-small">
            <li v-if="this.$store.state.authentication.user.isMainAdmin">
              <router-link class="dropdown-item" to="admin">Admin</router-link>
            </li>
            <li>
              <router-link class="dropdown-item" to="/">Dashboard</router-link>
            </li>
            <li v-if="this.$store.state.authentication.user.isMainAdmin">
              <router-link class="dropdown-item" to="/updaterd"
                >Update row data</router-link
              >
            </li>
            <li><hr class="dropdown-divider" /></li>
            <li>
              <router-link class="dropdown-item" to="login" @click="switchTheme"
                >Log out</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
/* eslint-disable */
import PdfLayoutChartsVue from "@/components/PdfLayoutCharts.vue";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "NavBar",
  components: {
    VueHtml2pdf,
    PdfLayoutChartsVue,
  },
  data() {
    return { User: {}, themeInput: false };
  },

  props: {
    classnames: {
      type: String,
      default: "",
    },
  },
  created() {
    this.User = this.$store.state.authentication.user;
  },
  mounted() {
    const currentTheme = localStorage.getItem("theme")
      ? localStorage.getItem("theme")
      : null;
    if (currentTheme) {
      document.documentElement.setAttribute("data-theme", currentTheme);

      if (currentTheme === "dark") {
        this.themeInput = true;
      }
    }

    // toggleSwitch.addEventListener("change", switchTheme, false);
  },
  methods: {
    switchTheme(e) {
      // $event.target.value
      // console.log("switch theme e");
      // console.log(e);
      const { dispatch } = this.$store;
      if (e.target.checked) {
        document.documentElement.setAttribute("data-theme", "dark");
        localStorage.setItem("theme", "dark"); //add this
        dispatch("authentication/changetheme", "dark");
      } else {
        dispatch("authentication/changetheme", "light");
        document.documentElement.setAttribute("data-theme", "light");
        localStorage.setItem("theme", "light"); //add this
      }
    },
  },
  computed: {
    theme() {
      var localtheme = this.$store.state.authentication.theme;
      localStorage.setItem("theme", localtheme); //add this
      return localtheme;
    },
  },
};
</script>

<style>
.dropdown-menu.show {
  background-color: var(--bg-color);
}
.dropdown-item {
  background-color: var(--bg-color);
  color: var(--font-color) !important;
}
.dropdown-item:hover {
  background-color: var(--bg-color) !important;
  color: var(--font-color) !important;
}
.dropdown-toggle::after {
  color: var(--font-color);
}
</style>

<template>
	<div class="container-fluid">
		<div class="row vh-100 justify-content-center align-items-center">
			<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7">
				<NavBar></NavBar>
				<div :class="` ${theme === 'light' ? 'shadow2 bg-white' : 'bg-dark'} p-4 mt-3`">
					<div class="row justify-content-around">
						<div class="col col-sm-12 col-md-12 col-lg-4 col-xl-3">
							<div class="d-flex justify-content-between align-items-center">
								<p class="p5">Crear/Editar usuario</p>
								<button
									class="btn btn-sm btn-outline-secondary py-0"
									@click="
										SelectedItem = {};
										isNewItem = true;
									">
									<i class="bi bi-plus-lg"></i>
								</button>
							</div>
							<hr />
							<div class="w-100">
								<div>
									<p class="p5">Nombre</p>
									<input class="form-control form-control-sm" placeholder="---" v-model="SelectedItem.firstName" />
								</div>
								<div class="mt-2">
									<p class="p5">Apellido</p>
									<input class="form-control form-control-sm" placeholder="---" v-model="SelectedItem.lastName" />
								</div>
								<div class="mt-2">
									<p class="p5">Nombre de usuario</p>
									<input type="search" class="form-control form-control-sm" placeholder="---" v-model="SelectedItem.userName" />
								</div>
								<div class="mt-2">
									<p class="p5">Contraseña</p>
									<input type="password" class="form-control form-control-sm" placeholder="---" v-model="SelectedItem.password" />
								</div>

								<hr />
								<div class="d-flex justify-content-end">
									<button class="btn btn-sm btn-primary w-100" @click="OnSave">Guardar</button>
								</div>
							</div>
						</div>
						<div class="col col-sm-12 col-md-12 col-lg-8 col-xl">
							<div class="table-responsive w-100" style="height: 300; overflow: auto">
								<table class="table table-sm table-hover">
									<thead>
										<tr>
											<th scope="col">ID</th>
											<th scope="col">Nombre</th>
											<th scope="col">Apellido</th>
											<th scope="col">Usuario</th>
											<th scope="col">Password</th>
											<th scope="col">Programas</th>
											<th scope="col">Admin</th>
											<th>Acciones</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="item in users" :key="item.id" :style="`${item.id == User.id ? 'background-color: var(--bg-color-2);' : ''}`">
											<td>{{ item.id }}</td>
											<td>{{ item.firstName }}</td>
											<td>{{ item.lastName }}</td>
											<td>{{ item.userName }}</td>
											<td>{{ item.password }}</td>
											<td>
												<div class="dropdown" style="position: inherit">
													<button
														:class="` btn btn-sm border border-0 dropdown-toggle px-0 py-0 ${theme === 'light' ? ' text-dark' : ' text-white'} `"
														type="button"
														id="dropdownMenu2"
														data-bs-auto-close="outside"
														data-bs-toggle="dropdown"
														aria-expanded="false">
														Programas
														<!-- {{ this.item.Programas.length }} -->
													</button>
													<ul class="dropdown-menu" aria-labelledby="dropdownMenu2" style="height: 300px !important; overflow: auto">
														<li>
															<div class="d-flex align-items-end px-3 mt-2 mb-3" style="width: 280px">
																<div class="me-2">
																	<input class="form-check-input" type="checkbox" />
																</div>
																<div class="input-group input-group-sm border-bottom border-dark">
																	<input type="search" placeholder="buscar..." class="form-control form-control-sm" />
																	<span class="input-group-text bg-white border border-0 py-0" id="inputGroup-sizing-sm"> <i class="bi bi-search"></i></span>
																</div>
															</div>
														</li>
														<li v-for="programa in item.programas" :key="programa.nombre">
															<button class="dropdown-item d-flex align-items-center py-0 p6" type="button">
																<input
																	class="form-check-input mt-0 me-3"
																	type="checkbox"
																	v-model="programa.isChecked"
																	aria-label="Checkbox for following text input"
																	@change="onCheckboxProgramaChange($event, item)" />
																{{ programa.nombre }}
															</button>
														</li>
													</ul>
												</div>
											</td>
											<td>
												<input type="checkbox" class="form-check-input" v-model="item.isMainAdmin" v-bind:id="item.id" @change="OnMainAdmin($event.target.checked, item)" />
											</td>
											<td>
												<button
													:class="` btn btn-sm ${theme === 'light' ? ' btn-light ' : ' btn-dark border-0'} `"
													@click="
														SelectedItem = item;
														isNewItem = false;
													">
													<i class="bi bi-pencil-fill"></i>
												</button>
												<button v-if="!item.isMainAdmin" :class="` btn btn-sm ms-2 ${theme === 'light' ? ' btn-light ' : ' btn-dark border-0'} `" @click="OnDelete(item)">
													<i class="bi bi-trash2"></i>
												</button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	/* eslint-disable */
	import NavBar from "@/components/NavBar.vue";
	import { authHeader } from "@/helpers/auth-header";
	import { userService } from "@/services";
	export default {
		name: "PageAdmin",
		components: {
			NavBar,
		},
		data() {
			return {
				// user: this.$store.state.authentication.user,
				users: [],
				SelectedItem: {
					UsuarioDoc: "",
				},
				isNewItem: true,
				Programas: [
					{
						isChecked: true,
						text: "Programa 1",
					},
					{
						isChecked: true,
						text: "Programa 2",
					},
					{
						isChecked: true,
						text: "Programa 1",
					},
				],
			};
		},

		methods: {
			async OnMainAdmin(e, item) {
				if (item) {
					const isMainadmin = e;
					//endpoint to save the user

					var trueProgramas = [];
					item.programas.forEach((programa) => {
						if (programa.isChecked) {
							trueProgramas.push(programa.nombre);
						}
					});

					var userPost = { ...item };
					userPost.usuarioDoc = "";
					userPost.isMainAdmin = isMainadmin;

					userPost.programas = trueProgramas.join(",");

					await this.getDataBody("api/users/create", userPost);
					// if (!userResponse) return;
					// if (this.isNewItem) this.users.push(userResponse);
					// this.SelectedItem = {};
					// this.isNewItem = true;
				}
			},
			async OnGetUsers() {
				var user = { ...this.$store.state.authentication.user };
				user.Password = "";
				user.UsuarioDoc = "";
				user.programas = "";
				const users = await this.getDataBody("api/users", user);

				const programas = await userService.getData(`/programas`);
				var listObjectProgramas = [];
				programas.forEach((programa, index) => {
					listObjectProgramas.push({
						isChecked: false,
						nombre: programa,
					});
				});

				const templist = [...listObjectProgramas];

				// this.users.forEach((userTemp) => {});

				for (let i = 0; i < users.length; i++) {
					const userTemp = users[i];
					var listtemp = [];
					templist.forEach((programa) => {
						var itemtemp = {};
						const exist = userTemp.programas ? (userTemp.programas.split(",").length === 0 ? false : userTemp.programas.split(",").some((o) => o === programa.nombre)) : false;
						if (!exist) {
							listtemp.push(programa);
						} else {
							// programa.isChecked = true;
							listtemp.push({
								isChecked: true,
								nombre: programa.nombre,
							});
						}
					});
					userTemp.programas = listtemp;
					listtemp = [];
				}
				this.users = users;
			},
			async OnDelete(param) {
				if (param) {
					//endpoint to save the user
					// var userPost = this.SelectedItem;
					param.UsuarioDoc = "";
					param.programas = "";
					const userResponse = await this.getDataBody("api/users/remove", param);
					const index = this.users.findIndex((item) => (item.id == param.id ? true : false));
					if (index === -1) return;
					this.users.splice(index, 1);
				}
			},
			async OnSave() {
				if (this.SelectedItem) {
					//endpoint to save the user
					var userPost = this.SelectedItem;
					userPost.UsuarioDoc = "";
					userPost.programas = "";
					const userResponse = await this.getDataBody("api/users/create", userPost);
					if (!userResponse) return;

					const programas = await userService.getData(`/programas`);
					var listObjectProgramas = [];
					programas.forEach((programa, index) => {
						listObjectProgramas.push({
							isChecked: false,
							nombre: programa,
						});
					});

					userResponse.programas = listObjectProgramas;

					if (this.isNewItem) this.users.push(userResponse);
					this.SelectedItem = {};
					this.isNewItem = true;
				}
			},
			async getDataBody(route, body) {
				const requestOptions = {
					method: "POST",
					mode: "cors",
					headers: authHeader(),
					body: JSON.stringify(body),
				};
				const jsonData = await fetch(`${userService.config.apiUrl}${route ? route : ""}`, requestOptions);

				return await jsonData.json();
			},
			OnLogout() {
				this.$router.push("login");
			},
			async onCheckboxProgramaChange(event, user) {
				console.log("user objects with programas list");
				console.log(user);

				var trueProgramas = [];
				user.programas.forEach((programa) => {
					if (programa.isChecked) {
						trueProgramas.push(programa.nombre);
					}
				});

				var userPost = { ...user };
				userPost.UsuarioDoc = "";
				userPost.programas = trueProgramas.join(",");
				console.log(userPost);

				const userResponse = await this.getDataBody("api/users/create", userPost);
				if (this.User.id === userResponse.id) {
					const { dispatch } = this.$store;
					dispatch("authentication/proUpdate", userResponse.programas);
				}
			},
		},
		computed: {
			theme() {
				return this.$store.state.authentication.theme;
			},
			User() {
				return this.$store.state.authentication.user;
			},
		},
		created() {
			this.OnGetUsers();
		},
	};
</script>

<style>
	.table {
		color: var(--font-color) !important;
	}

	.table-hover > tbody > tr:hover > * {
		color: var(--font-color) !important;
	}
</style>

<template>
  <Doughnut
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="idchart"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
  ></Doughnut>
</template>

<script>
/* eslint-disable */
import Chart from "chart.js/auto";
import { Doughnut } from "vue-chartjs";

export default {
  name: "PageDoghnut",
  components: { Doughnut },
  props: {
    idchart: {
      type: String,
      default: "doghnut-chart",
    },
    title: {
      type: String,
      default: "",
    },
    dataset: {
      type: Array,
      default: () => [],
    },
    datalabels: {
      type: Array,
      default: () => ["RED", "BLUE", "YELLOW"],
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      type: String,
      default: "w-100 d-flex justify-content-center",
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    chartOptions() {
      return {
        responsive: false,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: false,
            text: this.title,
          },
        },
      };
    },
    chartData() {
      const resp = {
        datasets: this.dataset,
        labels: this.datalabels,
      };
      return resp;
    },
  },
  methods: {
    OnSetChart() {
      const ctx = document.getElementById(this.idchart).getContext("2d");
      new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: ["Red", "Blue", "Yellow"],
          datasets: [
            {
              label: "# Top 7",
              data: [12, 19, 3],
              backgroundColor: ["#e5a54b", "#3f80ea", "#3C3F40"],
              borderColor: ["#e5a54b", "#e5a54b", "#e5a54b"],
              borderWidth: 1,
            },
          ],
        },
        options: {
          animations: {
            tension: {
              duration: 1000,
              easing: "linear",
              from: 1,
              to: 0,
              loop: true,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
  },
};
</script>

<template>
  <div>
    <NavBar :classnames="'container pt-4'"></NavBar>
    <router-view></router-view>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
export default {
  name: "MainLayout",
  components: {
    NavBar,
  },
};
</script>

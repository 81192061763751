<template>
  <div>
    <button
      @click="generateReport"
      class="btn btn-sm btn-dark py-1 px-2 me-2"
      :disabled="isExporting"
    >
      <p class="p5">{{ isExporting ? "Exportando..." : "Exportar PDF" }}</p>
    </button>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="filename"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf"
    >
      <!-- PDF Content Here -->
      <PdfDashboard
        @domRendered="domRendered"
        ref="form"
        slot="pdf-content"
      ></PdfDashboard>
    </vue-html2pdf>
  </div>
</template>

<script>
/* eslint-disable */
import VueHtml2pdf from "vue-html2pdf";
import PdfDashboard from "./PdfDashboard.vue";

export default {
  name: "PdfLayoutCharts",
  data() {
    return {
      componentRendered: false,
      download: false,
      isExporting: false,
    };
  },
  components: {
    VueHtml2pdf,
    PdfDashboard,
  },
  methods: {
    async generateReport() {
      this.isExporting = true;
      await this.$refs.form.updateChart();
      this.download = true;
      if (this.componentRendered) {
        // this.$refs.html2Pdf.generatePdf();
      }
      this.componentRendered = false;
    },
    domRendered: function (param) {
      console.log("dom rendered param");
      console.log(param);
      if (param && this.download) {
        this.$refs.html2Pdf.generatePdf();
      }
      this.componentRendered = true;
      this.isExporting = false;
    },
  },
};
</script>

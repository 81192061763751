/* eslint-disable */
import Vue from "vue";
import App from "./App.vue";
import { store } from "./store";
import { router } from "./routes";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";

Vue.config.productionTip = false;

new Vue({
  store,
  router: router,
  render: (h) => h(App),
}).$mount("#app");

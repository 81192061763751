<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-12 col-lg">
        <div class="row justify-content-center align-items-strech mt-4 mt-xl-0">
          <div class="col col-sm-8 col-md-9 col-lg-6">
            <div
              :class="`${
                theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
              } p-3`"
            >
              <div class="d-flex mb-2 align-items-center">
                <i class="bi bi-bar-chart me-2"></i>
                <p class="p6 text-muted">Rating</p>
              </div>
              <Bar
                idchart="11"
                :datalabels="BarRatingPrograma.labels"
                :dataset="BarRatingPrograma.dataset"
              ></Bar>
            </div>
          </div>
          <div class="col col-sm-8 col-md-9 col-lg-6">
            <div
              :class="`${
                theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
              } p-3`"
            >
              <div class="d-flex mb-2 align-items-center">
                <i class="bi bi-bar-chart me-2"></i>
                <p class="p6 text-muted">Rating por edad</p>
              </div>
              <BarY
                idchart="12"
                :datalabels="BarY.labels"
                :dataset="BarY.dataset"
                :title="BarY.title"
              ></BarY>
            </div>
          </div>
          <div class="col col-sm-8 col-md-9 col-lg-6 mt-2">
            <div
              :class="`${
                theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
              } p-3`"
            >
              <div class="d-flex mb-2 align-items-center">
                <i class="bi bi-pie-chart me-2"></i>
                <p class="p6 text-muted">Rating por genero</p>
              </div>
              <!-- <hr class="mt-2 mb-2" /> -->
              <Doughnut
                idchart="13"
                :datalabels="DoughnutRG.labels"
                :dataset="DoughnutRG.dataset"
              ></Doughnut>
            </div>
          </div>
          <div class="col col-sm-8 col-md-9 col-lg-6 mt-2">
            <div
              :class="`${
                theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
              } p-3`"
            >
              <div class="d-flex mb-2 align-items-center">
                <i class="bi bi-pie-chart me-2"></i>
                <p class="p6 text-muted">Rating por NSE</p>
              </div>
              <!-- <hr class="mt-2 mb-2" /> -->
              <Doughnut
                idchart="14"
                :datalabels="DoughnutNSE.labels"
                :dataset="DoughnutNSE.dataset"
              ></Doughnut>
            </div>
          </div>
          <div class="col col-sm-8 col-md-9 col-lg-6 mt-2">
            <div
              :class="`${
                theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
              } p-3`"
            >
              <div class="d-flex mb-2 align-items-center">
                <i class="bi bi-bar-chart me-2"></i>
                <p class="p6 text-muted">Alcance personas 18+</p>
              </div>
              <!-- <hr class="mt-2 mb-2" /> -->
              <Bar
                idchart="15"
                :datalabels="BarAP.labels"
                :dataset="BarAP.dataset"
              ></Bar>
            </div>
          </div>
          <div class="col col-sm-8 col-md-9 col-lg-6 mt-2">
            <div
              :class="`${
                theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
              } p-3`"
            >
              <div class="d-flex mb-2 align-items-center">
                <i class="bi bi-bar-chart me-2"></i>
                <p class="p6 text-muted">Alcance personas por genero</p>
              </div>
              <!-- <hr class="mt-2 mb-2" /> -->
              <Bar
                idchart="16"
                :datalabels="BarAPG.labels"
                :dataset="BarAPG.dataset"
              ></Bar>
            </div>
          </div>
          <div class="col col-sm-8 col-md-9 col-lg-6 mt-2">
            <div
              :class="`${
                theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
              } p-3`"
            >
              <div class="d-flex mb-2 align-items-center">
                <i class="bi bi-bar-chart me-2"></i>
                <p class="p6 text-muted">Fidelidad personas 18+</p>
              </div>
              <!-- <hr class="mt-2 mb-2" /> -->
              <Bar
                idchart="17"
                :datalabels="BarFidelidad.labels"
                :dataset="BarFidelidad.dataset"
              ></Bar>
            </div>
          </div>
          <div class="col col-sm-8 col-md-9 col-lg-6 mt-2">
            <div
              :class="`${
                theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
              } p-3`"
            >
              <div class="d-flex mb-2 align-items-center">
                <i class="bi bi-bar-chart me-2"></i>
                <p class="p6 text-muted">Fidelidad por genero</p>
              </div>
              <!-- <hr class="mt-2 mb-2" /> -->
              <Bar
                idchart="18"
                :datalabels="BarFPG.labels"
                :dataset="BarFPG.dataset"
              ></Bar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Bar from "@/components/Graphs/Bar.vue";
import BarY from "@/components/Graphs/BarY.vue";
import Doughnut from "@/components/Graphs/Doughnut.vue";
import LineStack from "@/components/Graphs/LineStacked.vue";
import { userService } from "@/services";

export default {
  name: "PdfDashboard",
  data() {
    return {
      Bar: {
        labels: [],
        dataset: [],
      },
      BarY: {
        labels: [],
        dataset: [],
      },
      BarAP: {
        labels: [],
        dataset: [],
      },
      BarAPG: {
        labels: [],
        dataset: [],
      },
      BarFidelidad: {
        labels: [],
        dataset: [],
      },
      BarFPG: {
        labels: [],
        dataset: [],
      },
      DoughnutRG: {
        labels: [],
        dataset: [],
      },
      DoughnutNSE: {
        labels: [],
        dataset: [],
      },
      BarRatingPrograma: {
        labels: [],
        dataset: [],
      },
    };
  },
  components: {
    Bar,
    BarY,
    Doughnut,
    LineStack,
  },
  methods: {
    async updateChart() {
      const requestBody = {
        canal: "",
        programa: this.$store.state.fetchparam.data.programa,
        fechas: [],
        rangoFechas: [this.date1, this.date2],
      };

      console.log("requestBody");
      console.log(requestBody);
      await this.getUpdateCharts(requestBody);
      this.$nextTick(() => {
        setTimeout(() => {
          this.$emit("domRendered", true);
        }, 3000);
      });
    },
    async getUpdateCharts(body) {
      const chart = await userService.getDataBody(`/programas2`, body);
      console.log(chart);
      const chartY = await userService.getDataBody(`/ratingedades`, body);

      const chartRG = await userService.getDataBody(`/ratinggenero`, body);

      const chartNSE = await userService.getDataBody(`/ratingnse`, body);

      const chartAlcance = await userService.getDataBody(
        `/alcancepersonas`,
        body
      );

      const chartAPG = await userService.getDataBody(
        `/alcancepersonasgenero`,
        body
      );

      const chartFidelidad = await userService.getDataBody(
        `/fidelidadpersonas`,
        body
      );

      const chartFPG = await userService.getDataBody(
        `/fidelidadpersonasgenero`,
        body
      );

      this.setChartBar(chart);
      this.setChartBarY(chartY);
      this.setChartRG(chartRG);
      this.setChartNSE(chartNSE);
      this.setChartAlcance(chartAlcance);
      this.setChartAPG(chartAPG);
      this.setChartFidelidad(chartFidelidad);
      this.setChartFPG(chartFPG);
    },
    setChartBar(chart) {
      var dataset = {
        data: [],
        backgroundColor: [],
        borderRadius: 5,
        borderWidth: 2,
        label: "",
      };

      var labels = [];

      chart.dataset.forEach((element) => {
        dataset.data.push(element.value);
        labels.push(element.label);
        dataset.backgroundColor.push("#1bb3de");
        dataset.label = chart.title;
      });

      this.BarRatingPrograma.dataset = [dataset];
      this.BarRatingPrograma.labels = labels;
      this.BarRatingPrograma.title = chart.header;
      // this.BarRatingPrograma.labels = [chart.label];
    },
    setChartBarY(chart) {
      var dataset = {
        data: [],
        backgroundColor: [],
        borderRadius: 10,
        borderWidth: 2,
        color: "#FFFFFF",
        label: "",
      };

      var labels = [];

      chart.dataset.forEach((element) => {
        dataset.data.push(element.value);
        dataset.backgroundColor.push("#1694b8");
        labels.push(element.label);
        dataset.label = chart.title;
      });

      this.BarY.dataset = [dataset];
      this.BarY.labels = labels;
      this.BarY.title = chart.header;
    },
    setChartRG(chart) {
      var dataset = {
        data: [],
        backgroundColor: [],
        borderRadius: 5,
        borderColor: [],
        borderWidth: 1,
        color: "white",
        label: "",
      };

      var labels = [];

      chart.dataset.forEach((element) => {
        dataset.data.push(element.value);
        labels.push(element.label);
        dataset.label = chart.title;
      });
      dataset.backgroundColor.push("#0d556a");
      dataset.backgroundColor.push("#1bb3de");

      dataset.borderColor.push("#0E6B86");
      dataset.borderColor.push("#1186A7");

      this.DoughnutRG.dataset = [dataset];
      this.DoughnutRG.labels = labels;
      this.DoughnutRG.title = chart.header;
    },
    setChartNSE(chart) {
      var dataset = {
        data: [],
        backgroundColor: [],
        borderRadius: 5,
        borderColor: [],
        borderWidth: 1,
        label: "",
      };

      var labels = [];

      chart.dataset.forEach((element) => {
        dataset.data.push(element.value);
        labels.push(element.label);
        dataset.label = chart.title;
      });
      dataset.backgroundColor.push("#0d556a");
      dataset.backgroundColor.push("#1bb3de");

      dataset.borderColor.push("#0E6B86");
      dataset.borderColor.push("#1186A7");

      this.DoughnutNSE.dataset = [dataset];
      this.DoughnutNSE.labels = labels;
      this.DoughnutNSE.title = chart.header;
    },
    setChartAlcance(chart) {
      var dataset = {
        data: [],
        backgroundColor: [],
        borderRadius: 5,
        borderWidth: 2,
        label: "",
      };

      var labels = [];

      chart.dataset.forEach((element) => {
        dataset.data.push(element.value);
        labels.push(element.label);
        dataset.backgroundColor.push("#1bb3de");
        dataset.label = chart.title;
      });

      this.BarAP.dataset = [dataset];
      this.BarAP.labels = labels;
      this.BarAP.title = chart.header;
    },
    setChartAPG(chart) {
      var dataset = {
        data: [],
        backgroundColor: [],
        borderRadius: 5,
        borderWidth: 2,
        label: "",
      };

      var dataset2 = {
        data: [],
        backgroundColor: [],
        borderRadius: 5,
        borderWidth: 2,
        label: "",
      };

      var labels = [];

      chart.dataset.forEach((element) => {
        labels.push(element.label);

        const data1 = element.dataset[0];
        dataset.data.push(data1.value);
        dataset.backgroundColor.push("#b8ecd7");
        dataset.label = data1.label;

        const data2 = element.dataset[1];

        dataset2.data.push(data2.value);
        dataset2.backgroundColor.push("#ecb8cd");
        dataset2.label = data2.label;
      });

      this.BarAPG.dataset = [dataset, dataset2];
      this.BarAPG.labels = labels;
      this.BarAPG.title = chart.header;
    },
    setChartFidelidad(chart) {
      var dataset = {
        data: [],
        backgroundColor: [],
        borderRadius: 5,
        borderWidth: 2,
        label: "",
      };

      var labels = [];

      chart.dataset.forEach((element) => {
        dataset.data.push(element.value);
        labels.push(element.label);
        dataset.backgroundColor.push("#1694b8");
        dataset.label = chart.title;
      });

      this.BarFidelidad.dataset = [dataset];
      this.BarFidelidad.labels = labels;
      this.BarFidelidad.title = chart.header;
    },
    setChartFPG(chart) {
      var dataset = {
        data: [],
        backgroundColor: [],
        borderRadius: 5,
        borderWidth: 2,
        label: "",
      };

      var dataset2 = {
        data: [],
        backgroundColor: [],
        borderRadius: 5,
        borderWidth: 2,
        label: "",
      };

      var labels = [];

      chart.dataset.forEach((element) => {
        labels.push(element.label);

        const data1 = element.dataset[0];
        dataset.data.push(data1.value);
        dataset.backgroundColor.push("#b8ecd7");
        dataset.label = data1.label;

        const data2 = element.dataset[1];

        dataset2.data.push(data2.value);
        dataset2.backgroundColor.push("#ecb8cd");
        dataset2.label = data2.label;
      });

      this.BarFPG.dataset = [dataset, dataset2];
      this.BarFPG.labels = labels;
      this.BarFPG.title = chart.header;
    },
  },
  async mounted() {
    // this.OnGetList();
    // this.OnClickCanal({ text: {} });

    this.$nextTick(() => {
      setTimeout(() => {
        this.$emit("domRendered", "some parameters");
      }, 3000);
    });
  },
  computed: {
    theme() {
      return "light";
    },
    date1() {
      return this.$store.state.fetchparam.data.dateRanges[1];
    },
    date2() {
      return this.$store.state.fetchparam.data.dateRanges[0];
    },
  },
};
</script>

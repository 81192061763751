<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "App",
  watch: {
    $route(to, from) {
      // clear alert on location change
      this.$store.dispatch("alert/clear");
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Heebo&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

* {
  font-family: "Heebo", sans-serif;
  transition: 0.09s;
  /* background-color: var(--bg-color) !important;
  color: var(--font-color) !important; */
}
/*
:root {
  --primary-color: #242424;
  --secondary-color: #ebebeb;
  --danger-color: #ee3d3d;
  --blue-color: #159ce4;
  --font-color: #2e2e2e;
  --bg-color: #ffffff;
  --heading-color: #292922;
} */

[data-theme="dark"] {
  --primary-color: #eeeeee;
  --secondary-color: #223e50;
  --danger-color: #e83434;
  --blue-color: #159ce4;
  --font-color: rgba(255, 255, 255, 0.808);
  --bg-color: #223e50;
  --bg-color-2: #223e50;
  --bg-button: #1c3546;
  --bs-white-rgb: #263f3f !important;
  --heading-color: #818cab;
}
[data-theme="light"] {
  --primary-color: #242424;
  --secondary-color: #ffffff;
  --danger-color: #ee3d3d;
  --blue-color: #159ce4;
  --font-color: #2e2e2e;
  --bg-color: #ffffff;
  --bg-color-2: #ffffff;
  --bg-button: #fcfcfc;
  --bs-white-rgb: #f5f9f9 !important;
  --heading-color: #292922;
}

body {
  transition: 0.2s;
  background-color: var(--bg-color) !important;
  color: var(--font-color) !important;
}

.btn-opacity {
  opacity: 0.2 !important;
}
.form-control {
  background-color: var(--bg-color) !important;
  color: var(--font-color) !important;
  border: 1px solid var(--bg-color) !important;
}
</style>

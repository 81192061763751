<template>
  <div>
    <main>
      <div class="container-fluid container-xxl mt-4">
        <div class="row justify-content-center mt-2 flex-wrap">
          <div class="col-12 col-sm-8 col-md-9 col-lg-3 col-lg-3">
            <div
              :class="`shadow2  ${theme === 'light' ? 'bg-white' : 'bg-dark'}`"
            >
              <div class="p-3">
                <div class="d-flex justify-content-between">
                  <div>
                    <p class="p5 fw-bolder">Titulo Programa</p>
                  </div>
                  <div>
                    <button
                      class="btn btn-sm pb-0 pt-0 p4"
                      @click="
                        TitulosProgramas.forEach((item) => {
                          item.selected = false;
                        });
                        IsProgramClicked = false;
                      "
                    >
                      <i class="bi bi-filter-circle"></i>
                    </button>
                  </div>
                </div>
                <div style="overflow: auto">
                  <div
                    class="d-flex align-items-center justify-content-center h-100"
                    v-if="IsProgramasLoading"
                  >
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  <div class="me-2" v-if="!IsProgramasLoading">
                    <button
                      v-for="item in TitulosProgramas"
                      :key="item.id"
                      @click="
                        TitulosProgramas.forEach(
                          (item) => (item.selected = false)
                        );
                        SelectedPrograma = item.text;
                        item.selected = !item.selected;
                        OnClickPrograma(item);
                        IsProgramClicked = true;
                      "
                      :class="`p4 pointer pt-0 pb-0 w-100 text-start rounded-1 ${
                        item.selected
                          ? 'border border-info border-1'
                          : 'border-0'
                      } mb-2`"
                      style="
                        background-color: var(--bg-button);
                        color: var(--font-color);
                        padding: 3px;
                      "
                    >
                      {{ item.id }}. {{ item.text }}
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="IsProgramClicked" class="p-3 mt-3">
                <div class="d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <i class="bi bi-calendar me-2"></i>
                    <p class="p5 fw-bolder">Seleccionar rango de fecha</p>
                  </div>
                  <div></div>
                </div>
                <div class="mt-2">
                  <div class="d-flex flex-wrap">
                    <div class="col-12 col-md-12 col-lg-12 col-xl-6 mt-2">
                      <p class="p5">Fecha inicial</p>
                      <input
                        class="form-control form-control-sm"
                        type="date"
                        v-model="InitialDate"
                      />
                    </div>
                    <div class="col-12 col-md-12 col-lg-12 col-xl-6 mt-2">
                      <p class="p5">Fecha final</p>
                      <input
                        class="form-control form-control-sm"
                        type="date"
                        v-model="FinalDate"
                      />
                    </div>
                  </div>
                  <div class="mt-2">
                    <button
                      class="btn btn-sm btn-primary w-100"
                      @click="onGetFechaRango"
                    >
                      enter
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="IsProgramClicked" class="p-3 mt-3">
                <!-- <div class="d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <i class="bi bi-calendar me-2"></i>
                    <p class="p5 fw-bolder">Fechas</p>
                  </div>
                  <div>
                    <button
                      class="btn btn-sm btn-light pb-0 pt-0 p4"
                      @click="
                        Fechas.forEach((item) => {
                          item.selected = false;
                        });
                        SelectedFechas = [];
                      "
                    >
                      <i class="bi bi-filter-circle"></i>
                    </button>
                  </div>
                </div> -->
                <!-- <hr class="mt-0 mb-1" />
                <div style="height: 300px; overflow: auto">
                  <div
                    class="d-flex align-items-center justify-content-center h-100"
                    v-if="IsFechasLoading"
                  >
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  <div class="me-2" v-if="!IsFechasLoading">
                    <button
                      v-for="item in Fechas"
                      :key="item.id"
                      @click="
                        item.selected = !item.selected;
                        OnClickFecha(item);
                      "
                      class="w-100 text-start p4 pointer rounded-1 pt-0 pb-0 mb-2"
                      :style="`background-color: var(--bg-button);
                        color: var(--font-color);
                        padding: 3px;
                        ${
                          item.selected
                            ? 'border: 1px solid #1186A7;'
                            : 'border: 0px;'
                        }
                        `"
                    >
                      {{ item.id }}. {{ item.text }}
                    </button>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg">
            <div
              class="row justify-content-center align-items-strech mt-4 mt-xl-0"
            >
              <div class="col col-sm-8 col-md-9 col-lg-6">
                <div
                  :class="`${
                    theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
                  } p-3`"
                >
                  <div class="d-flex mb-2 align-items-center">
                    <i class="bi bi-bar-chart me-2"></i>
                    <p class="p6 text-muted">Rating</p>
                  </div>
                  <Bar
                    idchart="11"
                    :datalabels="BarRatingPrograma.labels"
                    :dataset="BarRatingPrograma.dataset"
                  ></Bar>
                </div>
              </div>
              <div class="col col-sm-8 col-md-9 col-lg-6">
                <div
                  :class="`${
                    theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
                  } p-3`"
                >
                  <div class="d-flex mb-2 align-items-center">
                    <i class="bi bi-bar-chart me-2"></i>
                    <p class="p6 text-muted">Rating por edad</p>
                  </div>
                  <BarY
                    idchart="12"
                    :datalabels="BarY.labels"
                    :dataset="BarY.dataset"
                    :title="BarY.title"
                  ></BarY>
                </div>
              </div>
              <div class="col col-sm-8 col-md-9 col-lg-6 mt-2">
                <div
                  :class="`${
                    theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
                  } p-3`"
                >
                  <div class="d-flex mb-2 align-items-center">
                    <i class="bi bi-pie-chart me-2"></i>
                    <p class="p6 text-muted">Rating por genero</p>
                  </div>
                  <!-- <hr class="mt-2 mb-2" /> -->
                  <Doughnut
                    idchart="13"
                    :datalabels="DoughnutRG.labels"
                    :dataset="DoughnutRG.dataset"
                  ></Doughnut>
                </div>
              </div>
              <div class="col col-sm-8 col-md-9 col-lg-6 mt-2">
                <div
                  :class="`${
                    theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
                  } p-3`"
                >
                  <div class="d-flex mb-2 align-items-center">
                    <i class="bi bi-pie-chart me-2"></i>
                    <p class="p6 text-muted">Rating por NSE</p>
                  </div>
                  <!-- <hr class="mt-2 mb-2" /> -->
                  <Doughnut
                    idchart="14"
                    :datalabels="DoughnutNSE.labels"
                    :dataset="DoughnutNSE.dataset"
                  ></Doughnut>
                </div>
              </div>
              <div class="col col-sm-8 col-md-9 col-lg-6 mt-2">
                <div
                  :class="`${
                    theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
                  } p-3`"
                >
                  <div class="d-flex mb-2 align-items-center">
                    <i class="bi bi-bar-chart me-2"></i>
                    <p class="p6 text-muted">Alcance personas 18+</p>
                  </div>
                  <!-- <hr class="mt-2 mb-2" /> -->
                  <Bar
                    idchart="15"
                    :datalabels="BarAP.labels"
                    :dataset="BarAP.dataset"
                  ></Bar>
                </div>
              </div>
              <div class="col col-sm-8 col-md-9 col-lg-6 mt-2">
                <div
                  :class="`${
                    theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
                  } p-3`"
                >
                  <div class="d-flex mb-2 align-items-center">
                    <i class="bi bi-bar-chart me-2"></i>
                    <p class="p6 text-muted">Alcance personas por genero</p>
                  </div>
                  <!-- <hr class="mt-2 mb-2" /> -->
                  <Bar
                    idchart="16"
                    :datalabels="BarAPG.labels"
                    :dataset="BarAPG.dataset"
                  ></Bar>
                </div>
              </div>
              <div class="col col-sm-8 col-md-9 col-lg-6 mt-2">
                <div
                  :class="`${
                    theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
                  } p-3`"
                >
                  <div class="d-flex mb-2 align-items-center">
                    <i class="bi bi-bar-chart me-2"></i>
                    <p class="p6 text-muted">Fidelidad personas 18+</p>
                  </div>
                  <!-- <hr class="mt-2 mb-2" /> -->
                  <Bar
                    idchart="17"
                    :datalabels="BarFidelidad.labels"
                    :dataset="BarFidelidad.dataset"
                  ></Bar>
                </div>
              </div>
              <div class="col col-sm-8 col-md-9 col-lg-6 mt-2">
                <div
                  :class="`${
                    theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
                  } p-3`"
                >
                  <div class="d-flex mb-2 align-items-center">
                    <i class="bi bi-bar-chart me-2"></i>
                    <p class="p6 text-muted">Fidelidad por genero</p>
                  </div>
                  <!-- <hr class="mt-2 mb-2" /> -->
                  <Bar
                    idchart="18"
                    :datalabels="BarFPG.labels"
                    :dataset="BarFPG.dataset"
                  ></Bar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <div>
      <!-- <PdfDashboardVue></PdfDashboardVue> -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */

var moment = require("moment");
import VueHtml2pdf from "vue-html2pdf";
import Bar from "@/components/Graphs/Bar.vue";
import BarY from "@/components/Graphs/BarY.vue";
import Doughnut from "@/components/Graphs/Doughnut.vue";
import LineStack from "@/components/Graphs/LineStacked.vue";
import PdfDashboardVue from "@/components/PdfDashboard.vue";

import { authHeader } from "@/helpers/auth-header";
import { userService } from "@/services";
import hmtl2pdf from "html2pdf.js";

export default {
  name: "PageInicio",
  components: { Bar, Doughnut, BarY, LineStack, VueHtml2pdf, PdfDashboardVue },
  data() {
    return {
      Lista: [],
      Canales: [],
      Deportes: [],
      TitulosProgramas: [],
      Fechas: [],
      Meses: [],
      SelectedCanal: {},
      SelectedPrograma: {},
      dataToStore: {
        programa: "",
        dateRanges: [],
      },
      date: new Date().toISOString().substr(0, 10),
      InitialDate: new Date().toISOString().substr(0, 10),
      FinalDate: new Date().toISOString().substr(0, 10),
      SelectedFechas: [],
      IsProgramasLoading: false,
      IsFechasLoading: false,
      IsProgramClicked: false,
      Bar: {
        labels: [],
        dataset: [],
      },
      BarY: {
        labels: [],
        dataset: [],
      },
      BarAP: {
        labels: [],
        dataset: [],
      },
      BarAPG: {
        labels: [],
        dataset: [],
      },
      BarFidelidad: {
        labels: [],
        dataset: [],
      },
      BarFPG: {
        labels: [],
        dataset: [],
      },
      DoughnutRG: {
        labels: [],
        dataset: [],
      },
      DoughnutNSE: {
        labels: [],
        dataset: [],
      },
      BarRatingPrograma: {
        labels: [],
        dataset: [],
      },
    };
  },
  mounted() {
    // this.OnGetList();
    this.OnClickCanal({ text: {} });
    // this.$nextTick(() => {
    //   setTimeout(() => {
    //     this.$emit("domRendered");
    //   }, 1000);
    // });
  },
  methods: {
    domRendered() {
      console.log("Dom Has Rendered");
      this.contentRendered = true;
    },
    async onInputChanged(event) {
      // mes-dia-ano
      var date1 = moment(event.target.value).format("DD/MM/YYYY");
      const requestBody = {
        canal: "",
        programa: this.SelectedPrograma,
        fechas: [date1],
        rangoFechas: [],
      };

      await this.getUpdateCharts(requestBody);
    },
    async onGetFechaRango() {
      const date1 = moment(this.InitialDate).format("DD/MM/YYYY");
      const date2 = moment(this.FinalDate).format("DD/MM/YYYY");

      const requestBody = {
        canal: "",
        programa: this.SelectedPrograma,
        fechas: [],
        rangoFechas: [date1, date2],
      };

      this.dataToStore.dateRanges = [];
      this.dataToStore.dateRanges.push(date2);
      this.dataToStore.dateRanges.push(date1);
      const { dispatch } = this.$store;
      dispatch("fetchparam/setParams", this.dataToStore);
      await this.getUpdateCharts(requestBody);
    },
    async OnGetList() {
      const canales = await userService.getData("/canales");
      canales.forEach((item, index) => {
        this.Canales.push({ id: index, text: item, selected: false });
      });
    },
    async OnClickCanal(param) {
      this.SelectedCanal = param.text;
      this.IsProgramasLoading = true;
      this.TitulosProgramas = [];
      this.Fechas = [];
      this.SelectedFechas = [];
      // const programas = await userService.getData(`/programas`);
      const programas =
        this.$store.state.authentication.user.programas.split(",");
      programas.forEach((item, index) => {
        this.TitulosProgramas.push({
          id: index + 1,
          text: item,
          selected: false,
        });
      });
      this.IsProgramasLoading = false;
    },
    async OnClickPrograma(param) {
      this.Fechas = [];
      this.SelectedFechas = [];
      const fechas = await userService.getDataBody(`/fechas`, {
        canal: "",
        programa: param.text,
        fechas: [],
        rangoFechas: [],
      });

      fechas.forEach((item, index) => {
        this.Fechas.push({
          id: index + 1,
          text: item,
          selected: false,
        });
      });

      console.log(this.dataToStore);
      this.dataToStore.programa = param.text;
      const { dispatch } = this.$store;
      dispatch("fetchparam/setParams", this.dataToStore);
    },
    async OnClickFecha(param) {
      if (param.selected) {
        this.SelectedFechas.push(param.text);
      } else {
        //First we find the index;
        var indexValue = this.SelectedFechas.findIndex((item, index) => {
          if (item === param.text) {
            return true;
          }
        });
        if (indexValue === -1) return;
        this.SelectedFechas.splice(indexValue, 1);
      }
      const requestBody = {
        canal: "",
        programa: this.SelectedPrograma,
        fechas: this.SelectedFechas ? this.SelectedFechas : [],
        rangoFechas: [],
      };

      await this.getUpdateCharts(requestBody);
    },
    async getUpdateCharts(body) {
      const chart = await userService.getDataBody(`/programas2`, body);
      const chartY = await userService.getDataBody(`/ratingedades`, body);

      const chartRG = await userService.getDataBody(`/ratinggenero`, body);

      const chartNSE = await userService.getDataBody(`/ratingnse`, body);

      const chartAlcance = await userService.getDataBody(
        `/alcancepersonas`,
        body
      );

      const chartAPG = await userService.getDataBody(
        `/alcancepersonasgenero`,
        body
      );

      const chartFidelidad = await userService.getDataBody(
        `/fidelidadpersonas`,
        body
      );

      const chartFPG = await userService.getDataBody(
        `/fidelidadpersonasgenero`,
        body
      );

      this.setChartBar(chart);
      this.setChartBarY(chartY);
      this.setChartRG(chartRG);
      this.setChartNSE(chartNSE);
      this.setChartAlcance(chartAlcance);
      this.setChartAPG(chartAPG);
      this.setChartFidelidad(chartFidelidad);
      this.setChartFPG(chartFPG);
    },
    setChartBar(chart) {
      var dataset = {
        data: [],
        backgroundColor: [],
        borderRadius: 5,
        borderWidth: 2,
        label: "",
      };

      var labels = [];

      chart.dataset.forEach((element) => {
        dataset.data.push(element.value);
        labels.push(element.label);
        dataset.backgroundColor.push("#1bb3de");
        dataset.label = chart.title;
      });

      this.BarRatingPrograma.dataset = [dataset];
      this.BarRatingPrograma.labels = labels;
      this.BarRatingPrograma.title = chart.header;
      // this.BarRatingPrograma.labels = [chart.label];
    },
    setChartBarY(chart) {
      var dataset = {
        data: [],
        backgroundColor: [],
        borderRadius: 10,
        borderWidth: 2,
        color: "#FFFFFF",
        label: "",
      };

      var labels = [];

      chart.dataset.forEach((element) => {
        dataset.data.push(element.value);
        dataset.backgroundColor.push("#1694b8");
        labels.push(element.label);
        dataset.label = chart.title;
      });

      this.BarY.dataset = [dataset];
      this.BarY.labels = labels;
      this.BarY.title = chart.header;
    },
    setChartRG(chart) {
      var dataset = {
        data: [],
        backgroundColor: [],
        borderRadius: 5,
        borderColor: [],
        borderWidth: 1,
        color: "white",
        label: "",
      };

      var labels = [];

      chart.dataset.forEach((element) => {
        dataset.data.push(element.value);
        labels.push(element.label);
        dataset.label = chart.title;
      });
      dataset.backgroundColor.push("#0d556a");
      dataset.backgroundColor.push("#1bb3de");

      dataset.borderColor.push("#0E6B86");
      dataset.borderColor.push("#1186A7");

      this.DoughnutRG.dataset = [dataset];
      this.DoughnutRG.labels = labels;
      this.DoughnutRG.title = chart.header;
    },
    setChartNSE(chart) {
      var dataset = {
        data: [],
        backgroundColor: [],
        borderRadius: 5,
        borderColor: [],
        borderWidth: 1,
        label: "",
      };

      var labels = [];

      chart.dataset.forEach((element) => {
        dataset.data.push(element.value);
        labels.push(element.label);
        dataset.label = chart.title;
      });
      dataset.backgroundColor.push("#0d556a");
      dataset.backgroundColor.push("#1bb3de");

      dataset.borderColor.push("#0E6B86");
      dataset.borderColor.push("#1186A7");

      this.DoughnutNSE.dataset = [dataset];
      this.DoughnutNSE.labels = labels;
      this.DoughnutNSE.title = chart.header;
    },
    setChartAlcance(chart) {
      var dataset = {
        data: [],
        backgroundColor: [],
        borderRadius: 5,
        borderWidth: 2,
        label: "",
      };

      var labels = [];

      chart.dataset.forEach((element) => {
        dataset.data.push(element.value);
        labels.push(element.label);
        dataset.backgroundColor.push("#1bb3de");
        dataset.label = chart.title;
      });

      this.BarAP.dataset = [dataset];
      this.BarAP.labels = labels;
      this.BarAP.title = chart.header;
    },
    setChartAPG(chart) {
      var dataset = {
        data: [],
        backgroundColor: [],
        borderRadius: 5,
        borderWidth: 2,
        label: "",
      };

      var dataset2 = {
        data: [],
        backgroundColor: [],
        borderRadius: 5,
        borderWidth: 2,
        label: "",
      };

      var labels = [];

      chart.dataset.forEach((element) => {
        labels.push(element.label);

        const data1 = element.dataset[0];
        dataset.data.push(data1.value);
        dataset.backgroundColor.push("#b8ecd7");
        dataset.label = data1.label;

        const data2 = element.dataset[1];

        dataset2.data.push(data2.value);
        dataset2.backgroundColor.push("#ecb8cd");
        dataset2.label = data2.label;
      });

      this.BarAPG.dataset = [dataset, dataset2];
      this.BarAPG.labels = labels;
      this.BarAPG.title = chart.header;
    },
    setChartFidelidad(chart) {
      var dataset = {
        data: [],
        backgroundColor: [],
        borderRadius: 5,
        borderWidth: 2,
        label: "",
      };

      var labels = [];

      chart.dataset.forEach((element) => {
        dataset.data.push(element.value);
        labels.push(element.label);
        dataset.backgroundColor.push("#1694b8");
        dataset.label = chart.title;
      });

      this.BarFidelidad.dataset = [dataset];
      this.BarFidelidad.labels = labels;
      this.BarFidelidad.title = chart.header;
    },
    setChartFPG(chart) {
      var dataset = {
        data: [],
        backgroundColor: [],
        borderRadius: 5,
        borderWidth: 2,
        label: "",
      };

      var dataset2 = {
        data: [],
        backgroundColor: [],
        borderRadius: 5,
        borderWidth: 2,
        label: "",
      };

      var labels = [];

      chart.dataset.forEach((element) => {
        labels.push(element.label);

        const data1 = element.dataset[0];
        dataset.data.push(data1.value);
        dataset.backgroundColor.push("#b8ecd7");
        dataset.label = data1.label;

        const data2 = element.dataset[1];

        dataset2.data.push(data2.value);
        dataset2.backgroundColor.push("#ecb8cd");
        dataset2.label = data2.label;
      });

      this.BarFPG.dataset = [dataset, dataset2];
      this.BarFPG.labels = labels;
      this.BarFPG.title = chart.header;
    },
  },
  computed: {
    theme() {
      return this.$store.state.authentication.theme;
    },
  },
};
</script>

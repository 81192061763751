import { authHeader } from "@/helpers/auth-header";

const config = {
	apiUrl: process.env.VUE_APP_ROOT_API,
};

function login(username, password) {
	const requestOptions = {
		method: "POST",
		mode: "cors",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify({ username, password }),
	};

	return fetch(`${config.apiUrl}api/users/authenticate`, requestOptions)
		.then(handleResponse)
		.then((user) => {
			// login successful if there's a jwt token in the response
			if (user.token) {
				// store user details and jwt token in local storage to keep user logged in between page refreshes
				localStorage.setItem("user", JSON.stringify(user));
			}

			return user;
		});
}

function logout() {
	// remove user from local storage to log user out
	localStorage.removeItem("user");
}

function handleResponse(response) {
	return response.text().then((text) => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				// auto logout if 401 response returned from api
				logout();
				location.reload(true);
			}

			const error = (data && data.message) || response.statusText;
			return Promise.reject(error);
		}

		return data;
	});
}

async function getData(route) {
	const requestOptions = {
		method: "GET",
		mode: "cors",
		headers: authHeader(),
	};
	const jsonData = await fetch(`${config.apiUrl}api/concesonariosv2${route ? route : ""}`, requestOptions);

	return await jsonData.json();
}

async function getDataBody(route, body) {
	const requestOptions = {
		method: "POST",
		mode: "cors",
		headers: authHeader(),
		body: JSON.stringify(body),
	};
	const jsonData = await fetch(`${config.apiUrl}api/concesonariosv2${route ? route : ""}`, requestOptions);

	return await jsonData.json();
}

async function getDataForm(route, file) {
	var headers = authHeader();
	headers.Accept = "application/json";
	delete headers["Content-Type"];

	const requestOptions = {
		method: "POST",
		mode: "cors",
		headers: headers,
		body: file,
	};

	const jsonData = await fetch(`${config.apiUrl}api/concesonariosv2${route ? route : ""}`, requestOptions);

	return await jsonData.json();
}

export const userService = {
	config,
	getData,
	getDataBody,
	getDataForm,
	login,
	logout,
};

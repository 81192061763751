var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('main',[_c('div',{staticClass:"container-fluid container-xxl mt-4"},[_c('div',{staticClass:"row justify-content-center mt-2 flex-wrap"},[_c('div',{staticClass:"col-12 col-sm-8 col-md-9 col-lg-3 col-lg-3"},[_c('div',{class:`shadow2  ${_vm.theme === 'light' ? 'bg-white' : 'bg-dark'}`},[_c('div',{staticClass:"p-3"},[_c('div',{staticClass:"d-flex justify-content-between"},[_vm._m(0),_c('div',[_c('button',{staticClass:"btn btn-sm pb-0 pt-0 p4",on:{"click":function($event){_vm.TitulosProgramas.forEach((item) => {
                        item.selected = false;
                      });
                      _vm.IsProgramClicked = false;}}},[_c('i',{staticClass:"bi bi-filter-circle"})])])]),_c('div',{staticStyle:{"overflow":"auto"}},[(_vm.IsProgramasLoading)?_c('div',{staticClass:"d-flex align-items-center justify-content-center h-100"},[_vm._m(1)]):_vm._e(),(!_vm.IsProgramasLoading)?_c('div',{staticClass:"me-2"},_vm._l((_vm.TitulosProgramas),function(item){return _c('button',{key:item.id,class:`p4 pointer pt-0 pb-0 w-100 text-start rounded-1 ${
                      item.selected
                        ? 'border border-info border-1'
                        : 'border-0'
                    } mb-2`,staticStyle:{"background-color":"var(--bg-button)","color":"var(--font-color)","padding":"3px"},on:{"click":function($event){_vm.TitulosProgramas.forEach(
                        (item) => (item.selected = false)
                      );
                      _vm.SelectedPrograma = item.text;
                      item.selected = !item.selected;
                      _vm.OnClickPrograma(item);
                      _vm.IsProgramClicked = true;}}},[_vm._v(" "+_vm._s(item.id)+". "+_vm._s(item.text)+" ")])}),0):_vm._e()])]),(_vm.IsProgramClicked)?_c('div',{staticClass:"p-3 mt-3"},[_vm._m(2),_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"col-12 col-md-12 col-lg-12 col-xl-6 mt-2"},[_c('p',{staticClass:"p5"},[_vm._v("Fecha inicial")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.InitialDate),expression:"InitialDate"}],staticClass:"form-control form-control-sm",attrs:{"type":"date"},domProps:{"value":(_vm.InitialDate)},on:{"input":function($event){if($event.target.composing)return;_vm.InitialDate=$event.target.value}}})]),_c('div',{staticClass:"col-12 col-md-12 col-lg-12 col-xl-6 mt-2"},[_c('p',{staticClass:"p5"},[_vm._v("Fecha final")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.FinalDate),expression:"FinalDate"}],staticClass:"form-control form-control-sm",attrs:{"type":"date"},domProps:{"value":(_vm.FinalDate)},on:{"input":function($event){if($event.target.composing)return;_vm.FinalDate=$event.target.value}}})])]),_c('div',{staticClass:"mt-2"},[_c('button',{staticClass:"btn btn-sm btn-primary w-100",on:{"click":_vm.onGetFechaRango}},[_vm._v(" enter ")])])])]):_vm._e(),(_vm.IsProgramClicked)?_c('div',{staticClass:"p-3 mt-3"}):_vm._e()])]),_c('div',{staticClass:"col-12 col-md-12 col-lg"},[_c('div',{staticClass:"row justify-content-center align-items-strech mt-4 mt-xl-0"},[_c('div',{staticClass:"col col-sm-8 col-md-9 col-lg-6"},[_c('div',{class:`${
                  _vm.theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
                } p-3`},[_vm._m(3),_c('Bar',{attrs:{"idchart":"11","datalabels":_vm.BarRatingPrograma.labels,"dataset":_vm.BarRatingPrograma.dataset}})],1)]),_c('div',{staticClass:"col col-sm-8 col-md-9 col-lg-6"},[_c('div',{class:`${
                  _vm.theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
                } p-3`},[_vm._m(4),_c('BarY',{attrs:{"idchart":"12","datalabels":_vm.BarY.labels,"dataset":_vm.BarY.dataset,"title":_vm.BarY.title}})],1)]),_c('div',{staticClass:"col col-sm-8 col-md-9 col-lg-6 mt-2"},[_c('div',{class:`${
                  _vm.theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
                } p-3`},[_vm._m(5),_c('Doughnut',{attrs:{"idchart":"13","datalabels":_vm.DoughnutRG.labels,"dataset":_vm.DoughnutRG.dataset}})],1)]),_c('div',{staticClass:"col col-sm-8 col-md-9 col-lg-6 mt-2"},[_c('div',{class:`${
                  _vm.theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
                } p-3`},[_vm._m(6),_c('Doughnut',{attrs:{"idchart":"14","datalabels":_vm.DoughnutNSE.labels,"dataset":_vm.DoughnutNSE.dataset}})],1)]),_c('div',{staticClass:"col col-sm-8 col-md-9 col-lg-6 mt-2"},[_c('div',{class:`${
                  _vm.theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
                } p-3`},[_vm._m(7),_c('Bar',{attrs:{"idchart":"15","datalabels":_vm.BarAP.labels,"dataset":_vm.BarAP.dataset}})],1)]),_c('div',{staticClass:"col col-sm-8 col-md-9 col-lg-6 mt-2"},[_c('div',{class:`${
                  _vm.theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
                } p-3`},[_vm._m(8),_c('Bar',{attrs:{"idchart":"16","datalabels":_vm.BarAPG.labels,"dataset":_vm.BarAPG.dataset}})],1)]),_c('div',{staticClass:"col col-sm-8 col-md-9 col-lg-6 mt-2"},[_c('div',{class:`${
                  _vm.theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
                } p-3`},[_vm._m(9),_c('Bar',{attrs:{"idchart":"17","datalabels":_vm.BarFidelidad.labels,"dataset":_vm.BarFidelidad.dataset}})],1)]),_c('div',{staticClass:"col col-sm-8 col-md-9 col-lg-6 mt-2"},[_c('div',{class:`${
                  _vm.theme === 'light' ? 'bg-white' : 'bg-dark rounded-4'
                } p-3`},[_vm._m(10),_c('Bar',{attrs:{"idchart":"18","datalabels":_vm.BarFPG.labels,"dataset":_vm.BarFPG.dataset}})],1)])])])])])]),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_c('div')])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"p5 fw-bolder"},[_vm._v("Titulo Programa")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status"}},[_c('span',{staticClass:"visually-hidden"},[_vm._v("Loading...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"bi bi-calendar me-2"}),_c('p',{staticClass:"p5 fw-bolder"},[_vm._v("Seleccionar rango de fecha")])]),_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mb-2 align-items-center"},[_c('i',{staticClass:"bi bi-bar-chart me-2"}),_c('p',{staticClass:"p6 text-muted"},[_vm._v("Rating")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mb-2 align-items-center"},[_c('i',{staticClass:"bi bi-bar-chart me-2"}),_c('p',{staticClass:"p6 text-muted"},[_vm._v("Rating por edad")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mb-2 align-items-center"},[_c('i',{staticClass:"bi bi-pie-chart me-2"}),_c('p',{staticClass:"p6 text-muted"},[_vm._v("Rating por genero")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mb-2 align-items-center"},[_c('i',{staticClass:"bi bi-pie-chart me-2"}),_c('p',{staticClass:"p6 text-muted"},[_vm._v("Rating por NSE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mb-2 align-items-center"},[_c('i',{staticClass:"bi bi-bar-chart me-2"}),_c('p',{staticClass:"p6 text-muted"},[_vm._v("Alcance personas 18+")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mb-2 align-items-center"},[_c('i',{staticClass:"bi bi-bar-chart me-2"}),_c('p',{staticClass:"p6 text-muted"},[_vm._v("Alcance personas por genero")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mb-2 align-items-center"},[_c('i',{staticClass:"bi bi-bar-chart me-2"}),_c('p',{staticClass:"p6 text-muted"},[_vm._v("Fidelidad personas 18+")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex mb-2 align-items-center"},[_c('i',{staticClass:"bi bi-bar-chart me-2"}),_c('p',{staticClass:"p6 text-muted"},[_vm._v("Fidelidad por genero")])])
}]

export { render, staticRenderFns }
<template>
  <div class="container-fluid bg-dark">
    <div class="row vh-100 align-items-center justify-content-center">
      <div class="col col-sm-10 col-md-5 col-lg-3 col-xl-3">
        <div class="p-4 shadow2" style="background-color: white">
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <img
                src="../assets/images/tvn.png"
                class="p-1"
                style="height: 50px; border-radius: 50%"
              />
              <div class="ms-2">
                <p class="p6 fw-bold" style="color: black">Login</p>
                <p class="p4 text-muted mt-1">Row data concesonarios</p>
              </div>
            </div>
            <div>
              <p class="p6"></p>
            </div>
          </div>
          <hr />
          <form @submit.prevent="handleSubmit">
            <div class="mt-1">
              <p class="p5 text-muted">Usuario</p>
              <input
                type="search"
                name="username"
                v-model="username"
                class="form-control-2 mt-2 w-100 py-2 px-2"
                placeholder=""
                :class="{ 'is-invalid': submitted && !username }"
              />
            </div>
            <div class="mt-3">
              <p class="p5 text-muted">Password</p>
              <input
                type="password"
                name="password"
                v-model="password"
                class="form-control-2 mt-2 w-100 py-2 px-2"
                placeholder="*********"
                :class="{ 'is-invalid': submitted && !password }"
              />
            </div>
            <div class="d-flex justify-content-between mt-3">
              <div :class="`alert ${alert.type} py-1 my-0 p4`" role="alert">
                {{ alert.message }}
              </div>
              <button
                class="btn btn-sm btn-outline-secondary border-secondary"
                :disabled="loggingIn"
              >
                <div class="d-flex align-items-center">
                  <i v-if="!loggingIn" class="bi bi-box-arrow-in-right"></i>
                  <!-- <span v-if="!loggingIn" class="material-icons"> login </span> -->

                  <Loader v-show="loggingIn"></Loader>
                  <p class="p4 ms-2">Validar</p>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
export default {
  name: "PageLogin",
  components: {
    Loader,
  },
  data() {
    return {
      username: "",
      password: "",
      ipAddress: "",
      localidad: {},
      submitted: false,
    };
  },
  computed: {
    loggingIn() {
      return this.$store.state.authentication.status.loggingIn;
    },
    alert() {
      return this.$store.state.alert;
    },
  },
  mounted() {
    // console.log("process.env.VUE_APP_ROOT_API");
    // console.log(process.env.VUE_APP_ROOT_API);
  },
  methods: {
    Onlogin() {
      this.$router.push("/");
    },
    handleSubmit() {
      this.submitted = true;
      const { username, password } = this;
      const { dispatch } = this.$store;

      if (username && password) {
        dispatch("authentication/login", { username, password });
      }
    },
  },
  async created() {
    this.$store.dispatch("authentication/logout");
  },
};
</script>

<style>
.form-control-2 {
  /*border-top: 0px solid black;
            border-bottom: 2px solid black;
            border-left: 0px solid black;
            border-right: 0px solid black;*/
  border: 1px solid #dce0e6;
  font-size: 12px;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
}

.form-control-2:focus {
  outline: none;
}
</style>

<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="idchart"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import Chart from "chart.js/auto";
import { Bar } from "vue-chartjs";

export default {
  name: "PageBarY",
  components: {
    Bar,
  },
  props: {
    idchart: {
      type: String,
      default: "bar-chart",
    },
    title: {
      type: String,
      default: "",
    },
    dataset: {
      type: Array,
      default: () => [],
    },
    datalabels: {
      type: Array,
      default: () => [],
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  mounted() {
    // this.OnSetChartBar();
  },
  computed: {
    chartOptions() {
      return {
        indexAxis: "y",
        responsive: true,
        cutout: 20,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: false,
            text: this.title,
          },
        },
      };
    },
    chartData() {
      const resp = {
        labels: this.datalabels,
        datasets: this.dataset,
      };
      return resp;
    },
  },
  methods: {
    OnSetChartBar() {
      const ctx = document.getElementById(this.idchart).getContext("2d");
      new Chart(ctx, {
        type: "bar",

        data: {
          labels: this.datalabels,
          datasets: [
            {
              label: "Edades",
              data: this.dataset,
              backgroundColor: [
                "#007de5",
                "#007de5",
                "#007de5",
                "#007de5",
                "#007de5",
              ],
              borderColor: [
                "#007de5",
                "#007de5",
                "#007de5",
                "#007de5",
                "#007de5",
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          indexAxis: "y",
          animations: {
            tension: {
              duration: 1000,
              easing: "linear",
              from: 1,
              to: 0,
              loop: true,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
  },
};
</script>
